@tailwind base;
@tailwind components;
@tailwind utilities;




@import url('https://fonts.cdnfonts.com/css/newyork');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

* {
  cursor: none !important;
}


html,
body {
  /* overflow-x: hidden;  */
  /* overflow-y: scroll;  */
  /* scrollbar-width: none;  */
  /* -ms-overflow-style: none;  */
  width: 100%; 
  margin: 0; 
  font-family: 'Montserrat', sans-serif;
}


/* Apply New York font to all headers */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'NewYork', sans-serif;

}

a {
  text-decoration: none;
  color: inherit;
}


.landing-section {
  background-image: url('./assets/background_ocean.png');
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/*Scrollbar Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white; 
  border-radius: 15px;
}


 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #015A58; 
  border-radius: 15px;
}

.custom-cursor {
  position: fixed;
  pointer-events: none; /* Prevents the cursor from interfering with other elements */
  z-index: 1000; /* Ensures it’s above other elements */
  transition: transform 0.3s ease-out; /* Smooth scaling */
}

.cursor__ball {
  mix-blend-mode: difference; /* For blending effects */
}

.cursor__ball--big {
  position: absolute;
}

#CompetitiveAnalysis ul li{
  text-indent: -1.3em;
  padding-left: 1.3em;
}

/* @media (max-width: 499px) {
  .casestudy-container {
      max-width: none;
  }
} */

@media (min-width: 500px) {
  .casestudy-container {
      max-width: none;
  }
}






